@use "sass:color";

$color-1: #012E40;
$color-2: #024959;
$color-3: #026773;
$color-4: #3CA6A6;
$color-5: #F2E3D5;

body {
    overflow-x: hidden;
}

a {
    &, &:hover, &:focus, &:active, &.active {
        outline: 0;
    }
}

p {
    font-size: 20px;
    &.small {
        font-size: 16px;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: uppercase;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 700;
}

input:-webkit-autofill {
    &, &:hover, &:focus, &:active {
        transition: background-color 99999s ease-in-out 0s;
        -webkit-text-fill-color: white !important;
    }
}

.panel { 
    background-color: transparent; 
    border-color: $color-3;
}

.modal-content {
    background-color: $color-2;
    color: #fff;
}

.line {
    margin: 2em auto 4em;
    padding: 0;
    max-width: 35em;
    border: 0;
    border-top: solid 3px;
    text-align: center;
    overflow: visible;
    border-color: $color-3;
    &:after{
        color: $color-3;
        background-color: $color-2;
        display: inline-block;
        position: relative;
        top: -.8em;
        padding: 0 .25em;
        font-family: FontAwesome;
        font-size: 1.8em;
    }
    &.projects:after {
        content: "\f07c";
    }
    &.about:after {
        content: "\f4da";
        background-color: $color-1;
    }
    &.contact:after {
        content: "\f27a";
    }
}

.navbar-nav

.img-centered {
    margin: 0 auto;
}

.btn {
    margin-top: 1em;
    border: solid 2px #fff;
    font-size: 20px;
    color: #fff;
    background: 0 0;
    background-color: $color-2;
    transition: all .2s ease-in-out;
    &:hover, &:focus, &:active, &.active {
        color: #fff;
        background-color: $color-4;
    }
    .fa {
        margin-right: .5em;
    }
}

.navbar-nav {
    > .active > a {
    }
}

.navbar-default {
    text-transform: uppercase;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 700;
    a:focus {
        outline: 0;
    }
    .navbar-nav {
        letter-spacing: 1px;
        > li > a {
            &:hover,
            &:focus {
                background-color: $color-3;
            }
        }
        > .active > a {
            &,
            &:hover,
            &:focus {
                background-color: $color-4 !important;
            }
        }
    }
}


.navbar-default,
.navbar-inverse {
    border: 0;
    background-color: color.adjust($color-1, $lightness: 3.5%);
}

.dark-bg {
    color: #fff;
    background: $color-1;
}
.light-bg {
    color: #fff;
    background: $color-2;
}
section {
    padding: 100px 0;
    h2 {
        margin: 0;
        font-size: 3em;
    }
}

header {
    text-align: center;
    color: #fff;
    background: $color-1;
    .container {
        padding-top: 100px;
        padding-bottom: 50px;
    }
    .profile-image {
        margin: 0 auto 2em;
        max-width: 80%;
        border-radius: 50%;
    }
    .skills {
        font-size: 1em;
        font-weight: 300;
        margin: 1em auto;
    }
    .intro-text {
        .name {
            display: block;
            text-transform: uppercase;
            font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
            font-size: 2em;
            font-weight: 700;
        }
    }
    .btn-social {
        display: inline-block;
        width: 50px;
        height: 50px;
        border: 2px solid #fff;
        border-radius: 100%;
        text-align: center;
        font-size: 20px;
        line-height: 45px;
        color: #fff;
        &:hover {
            color: #fff;
        }
    }    
}

#portfolio {
    .portfolio-item {
        margin-bottom: 2em;
        &:hover .project-thumb {
            border-color: color.adjust($color-4, $lightness: 22%);
        }
    }
    .portfolio-link {
        display: block;
        position: relative;
        margin: 0 auto;
        max-width: 400px;
        text-align: center;
        &:focus {
            outline: none;
        }
    }
    .project-thumb {
        margin: 0 auto;
        border-radius: 50%;
        max-width: 12rem;
        border: 0.4em solid $color-4;
        transition: all .2s ease-in-out;
    }
    .project-name {
        width: 70%;
        display: inline-block;
        margin: 1em auto 0;
        color: white;
        text-transform: uppercase;
    }
}

#contact {
    .row {
        margin-left: 0;
        margin-right: 0;
    }
}
.floating-label-form-group {
    position: relative;
    padding-bottom: .5em;
    background-color: $color-3;
    border-bottom: 5px solid $color-1;
    &::not(:first-child) {
        padding-left: 14px;
        border-left: 1px solid #eee;
    }
    label {
        display: block;
        z-index: 0;
        position: relative;
        top: 2em;
        margin: 0;
        font-size: .85em;
        line-height: 1.764705882em;
        vertical-align: middle;
        vertical-align: baseline;
        opacity: 0;
        color: $color-4;
        -webkit-transition: top .3s ease,opacity .3s ease;
        -moz-transition: top .3s ease,opacity .3s ease;
        -ms-transition: top .3s ease,opacity .3s ease;
        transition: top .3s ease,opacity .3s ease;
    }
    input, textarea {
        z-index: 1;
        position: relative;
        padding-right: 0;
        padding-left: 0;
        border: 0;
        border-radius: 0;
        font-size: 1.5em;
        box-shadow: none!important;
        resize: none;
        background-color: transparent;
        color: white;
    }
    &-with-value label {
        top: 6px;
        opacity: 1;
    }
    &-with-focus label {
        color: $color-1;
    }

}

.form-control {
    padding: 0 12px 6px;
}

footer {
    color: #fff;

    h3 {
        margin-bottom: 30px;
    }

    .footer-above {
        padding-top: 50px;
        background-color: $color-3;
    }

    .footer-col {
        margin-bottom: 50px;
    }

    .footer-below {
        padding: 25px 0;
        background-color:$color-4;
    }
}

.scroll-top {
    z-index: 1049;
    position: fixed;
    right: 2%;
    bottom: 2%;
    width: 50px;
    height: 50px;
    .btn {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        font-size: 20px;
        line-height: 28px;
    
        &:focus {
            outline: 0;
        }
    }
}

.portfolio-modal {
    .modal-content {
        padding: 50px 0;
        min-height: 100%;
        border: 0;
        border-radius: 0;
        text-align: center;
        background-clip: border-box;
        -webkit-box-shadow: none;
        box-shadow: none;

        .item-details {
            margin: 30px 0;
        }
    }

    .close-modal {
        position: absolute;
        top: 25px;
        right: 25px;
        width: 70px;
        height: 70px;
        cursor: pointer;
        .rl {
            z-index: 1052;
            width: 1px;
            height: 55px;
            background-color: #fff;
            -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
        }
        .lr {
            z-index: 1051;
            width: 1px;
            height: 55px;
            margin-left: 35px;
            background-color: #fff;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    .project-title {
        margin: 1em auto;
    }

    .tag-container {
        margin: 2em auto 4em;
    }

    .label {
        display: inline-block;
        padding: .4em .6em;
        border-radius: .4em;
        margin: .2em;
    }

    .screenshot-container {
        margin-bottom: 2em;
    }

    .screenshot-image-wrapper {
        border: 5px solid $color-4;
    }

    .screenshot-image {
        width: 100%;
    }
}

.modal.fade.in {
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
}

@media (min-width: 640px) {
    .portfolio-flex-grid .portfolio-flex-row {
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      justify-content: center;
    }

    #portfolio {
        .portfolio-item {
            flex: 1 0 calc(50% - 10px);
            max-width: calc(50% - 10px);
            margin-left: 5px; 
            margin-right: 5px; 
        }
        .project-thumb {
            border: 0.4em solid $color-4;
        }


    }

    .portfolio-modal {
        .screenshot-container {
            margin-bottom: 4em;
        }
    
        .screenshot-image-wrapper.m {
            width: 30%;
            margin: 0 auto;
        }
    }
}

@media(min-width:768px) {
    header {
        .container {
            padding-top: 200px;
            padding-bottom: 100px;
        }
        .intro-text {
            .name {
                font-size: 4em;
            }
            .skills {
                font-size: 1.75em;
            }
        } 
    } 
    .navbar-fixed-top {
        padding: 25px 0;
        -webkit-transition: padding .3s;
        -moz-transition: padding .3s;
        transition: padding .3s;
        &.navbar-shrink {
            padding: 10px 0;
            .navbar-brand {
                font-size: 1.5em;
            }
        }
        .navbar-brand {
            font-size: 2em;
            -webkit-transition: all .3s;
            -moz-transition: all .3s;
            transition: all .3s;
        }
    }
    section {
        padding: 75px 0;
        &.first {
            padding-top: 75px;
        }
    }
    #portfolio {
        .portfolio-flex-item {
            flex: 1 0 calc(25% - 10px);
            max-width: calc(25% - 10px);
            margin-left: 5px; 
            margin-right: 5px; 
        }
    
        .project-thumb {
            max-width: 9rem;
        }

        .project-name {
            font-size: 1.2em;
            width: 100%;
        }
    }


}

@media (min-width: 992px) {
    #portfolio {

        .project-name {
            // font-size: 1.1em;
        }
    }
}

@media (min-width: 1200px) {
    #portfolio {
        .portfolio-flex-item {
            // flex: 1 0 calc(14.2% - 10px);
            max-width: calc(20% - 5px);
        }
    
        .project-thumb {
            max-width: 12rem;
        }

        .project-name {
        }
    }
}
